import { render, staticRenderFns } from "./CourseMessageList.vue?vue&type=template&id=62232927&scoped=true&"
import script from "./CourseMessageList.vue?vue&type=script&lang=ts&"
export * from "./CourseMessageList.vue?vue&type=script&lang=ts&"
import style0 from "./CourseMessageList.vue?vue&type=style&index=0&id=62232927&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62232927",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VAlert,VChip,VDivider,VIcon,VList,VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemSubtitle,VListItemTitle,VPagination})
